<template></template>

<script setup lang="ts">
  import { defineColumnFilter, defineColumnProps } from "../../lib/defines";
  import { FilterMatchMode } from "primevue/api";

  defineExpose(
    Object.assign(
      {},
      defineColumnProps({
        sortable: true,
        dataType: "date",
      }),
      defineColumnFilter({
        matchMode: FilterMatchMode.DATE_BEFORE,
      }),
    ),
  );
</script>

<style scoped></style>

